import { useEffect, useRef } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTitle } from 'react-use';
import Spinner from '../../components/spinner';
import FormError from '../../components/form-error';
import { useDepartments } from '../../hooks/use-departments';
import { CreateDeviceForm } from '../../interfaces/central-devices/create-device-request';
import { useCreateCentralDevice } from '../../hooks/centeral-devices/use-create-central-device';
import { useUpdateCentralDevice } from '../../hooks/centeral-devices/use-update-central-device';
import Select from 'react-select';
import { useLocations } from '../../hooks/use-locations';
import { useEmployees } from '../../hooks/use-employees';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCentralDevice } from '../../hooks/centeral-devices/use-get-central-device';
import { Device } from '../../interfaces/central-devices/device';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';

const schema = yup
  .object({
    name: yup.string().required().min(3, 'at-least 3 character'),
    default_staff_login_method: yup.string().required('Please select an item'),
    // take_pic: yup.boolean().test('req', 'This option is required', (v, c) => {
    //   // const is_pin_required = c.parent.is_pin_required;
    //   const snapshot = c.parent.snapshot;
    //   if (snapshot && !v) return false;
    //   return true;
    // }),
    take_pic: yup.boolean(),
    take_pic_on_break: yup.boolean(),
    take_pic_on_leave_request: yup.boolean(),
    is_pin_required: yup.boolean().test('req', 'This option is required', (v, c) => {
      const pin: string = c.parent.default_staff_login_method;
      if (pin === 'pin' && !v) return false;
      return true;
    }),
    staff_filter_types: yup.string(),
    locations: yup.string().test('req', 'This field is required', (v, c) => {
      const type = c.parent.staff_filter_types;
      if (type === 'all' || type === 'department' || (type === 'location' && v)) return true;
      return false;
    }),
    departments: yup.array().test('req', 'This field is required', (v: any, c) => {
      const type = c.parent.staff_filter_types;
      if (type === 'all' || type === 'location' || (type === 'department' && v.length)) return true;
      return false;
    }),
    staff_login_methods: yup
      .array()
      .test('req', 'Choose a default from selected login methods.', (v: any, c) => {
        const type = c.parent.default_staff_login_method;
        const find = v.find((i: any) => i.value === type);
        if (find) {
          return true;
        }
        return false;
      }),
    admins: yup.object({
      staff: yup.array(),
      // .test('req', 'This field is required', (v: any, c) => {
      //   if (v.length) return true;
      //   return false;
      // })
      merchant: yup.array(),
    }),
  })
  .required();

export const deviceLoginMethods = [
  { value: 'pin', label: 'Pin' },
  { value: 'id_pin', label: 'ID Pin' },
  { value: 'name_pin', label: 'Name Pin' },
  { value: 'rfid', label: 'RFID' },
  { value: 'face', label: 'Face' },
  { value: 'finger', label: 'Finger' },
  { value: 'qr_code', label: 'QR Code' },
];

const NewDevice = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | New Device`);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: device } = useGetCentralDevice(id);
  const { data: departments = [] } = useDepartments();
  const createCentralDevice = useCreateCentralDevice();
  const updateCentralDevice = useUpdateCentralDevice();
  const { data: locationsData = [] } = useLocations();
  const { data: employees = [] } = useEmployees();
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<CreateDeviceForm>({
    defaultValues: {
      name: '',
      staff_filter_types: 'all',
      locations: '',
      departments: [],
      take_pic: false,
      take_pic_on_break: false,
      take_pic_on_leave_request: false,
      is_pin_required: false,
      snapshot: false,
      default_staff_login_method: 'pin',
      staff_login_methods: [{ value: 'pin', label: 'Pin' }],
      admins: {
        staff: [],
        merchant: [],
      },
    },
    resolver: yupResolver<any>(schema),
  });
  const departmentsOptions = departments.map((l) => {
    return {
      value: l.id,
      label: l.title,
    };
  });
  const employeesOptions = employees.map((emp) => {
    return {
      value: emp.id,
      label: `${capitalizeFirstLetter(emp.first_name)} ${emp.last_name}`,
    };
  });
  const {
    staff_filter_types,
    take_pic,
    take_pic_on_break,
    take_pic_on_leave_request,
    snapshot,
    is_pin_required,
  } = watch();
  const snapshotRef = register('snapshot');

  useEffect(() => {
    if (!device) return;
    const staff = device.admins.staff.map((emp) => {
      const s = employees.find((e) => e.id === emp)!;
      return {
        value: emp,
        label: s?.first_name && s?.last_name ? `${s.first_name} ${s.last_name}` : emp.toString(),
      };
    });
    const deps = device.staff_filter_types.departments.map((emp) => {
      const d = departments.find((e) => e.id === emp)!;
      return {
        value: emp,
        label: d.title,
      };
    });
    const staffLoginMethods: any[] = [];
    for (const key in device.staff_login_methods) {
      if (Object.prototype.hasOwnProperty.call(device.staff_login_methods, key)) {
        const element = device.staff_login_methods[key as keyof Device['staff_login_methods']];
        const find = deviceLoginMethods.find((item) => item.value === key && element);
        if (find) {
          staffLoginMethods.push(find);
        }
      }
    }
    reset({
      name: device.name,
      take_pic: device.take_pic,
      take_pic_on_break: device.take_pic_on_break,
      take_pic_on_leave_request: device.take_pic_on_leave_request,
      is_pin_required: device.is_pin_required,
      snapshot: device.take_pic ? true : false,
      staff_filter_types: device.staff_filter_types.all
        ? 'all'
        : device.staff_filter_types.location
        ? 'location'
        : 'department',
      locations: device.staff_filter_types.locations[0] ?? '',
      departments: deps,
      default_staff_login_method: device.default_staff_login_method,
      staff_login_methods: staffLoginMethods,
      admins: {
        staff,
        merchant: [],
      },
    });
  }, [reset, device, employees, departments]);

  const onSubmit: SubmitHandler<CreateDeviceForm> = (data) => {
    const staffLoginMethods = data.staff_login_methods.map((item) => item.value);
    const formData = {
      name: data.name,
      take_pic: data.take_pic,
      take_pic_on_break: data.take_pic_on_break,
      take_pic_on_leave_request: data.take_pic_on_leave_request,
      is_pin_required: data.is_pin_required,
      staff_filter_types: {
        all: data.staff_filter_types === 'all' ? true : false,
        location: data.staff_filter_types === 'location' ? true : false,
        locations: data.staff_filter_types === 'location' ? [data.locations] : [],
        department: data.staff_filter_types === 'department' ? true : false,
        departments:
          data.staff_filter_types === 'department'
            ? data.departments.map((item) => item.value)
            : [],
      },
      default_staff_login_method: data.default_staff_login_method,
      staff_login_methods: {
        pin: staffLoginMethods.includes('pin') ? true : false,
        id_pin: staffLoginMethods.includes('id_pin') ? true : false,
        name_pin: staffLoginMethods.includes('name_pin') ? true : false,
        rfid: staffLoginMethods.includes('rfid') ? true : false,
        face: staffLoginMethods.includes('face') ? true : false,
        finger: staffLoginMethods.includes('finger') ? true : false,
        qr_code: staffLoginMethods.includes('qr_code') ? true : false,
      },
      admins: {
        staff: data.admins.staff.map((item) => item.value),
        merchant: [],
      },
    };
    if (id) {
      updateCentralDevice.mutate({ ...formData, id });
    } else {
      createCentralDevice.mutate(formData);
    }
  };

  return (
    <div ref={containerRef} className="w-full mx-auto space-y-4 pb-14">
      <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9">
        <div className="flex w-full pb-6 font-medium border-b">Device Details</div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-62">
          <div className="space-y-2 divide-y divide-gray-200">
            <div className="py-2">
              <div className="grid grid-cols-12 m-6 md:m-0 md:mt-6 gap-y-2 gap-x-4">
                <div className="sm:col-span-12">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Device Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="name"
                      {...register('name')}
                      autoComplete="given-name"
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <p className="h-8 pt-1 text-sm text-red-500 first-letter:capitalize">
                    {errors.name?.message}
                  </p>
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="login-method" className="block text-sm font-medium text-gray-700">
                    Login Methods
                  </label>
                  <div className="mt-2">
                    <Controller
                      name="staff_login_methods"
                      control={control}
                      render={({ field }) => (
                        <Select
                          isMulti={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          options={deviceLoginMethods as any}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <p className="h-8 pt-1 text-sm text-red-500 first-letter:capitalize">
                    {errors.staff_login_methods?.message}
                  </p>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="default_staff_login_method"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Default Login Method
                  </label>
                  <div className="mt-2">
                    <select
                      id="default_staff_login_method"
                      {...register('default_staff_login_method')}
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option disabled value={-1}>
                        Select a default login method
                      </option>
                      {deviceLoginMethods.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <p className="h-8 pt-1 text-sm text-red-500 first-letter:capitalize">
                    {errors.default_staff_login_method?.message}
                  </p>
                </div>
                <div className="sm:col-span-12">
                  <div className="flex items-center w-full mt-2">
                    <input
                      type="checkbox"
                      disabled={false}
                      id="is_pin_required"
                      {...register('is_pin_required')}
                      className="w-4 h-4 text-indigo-600 border-gray-300 rounded self-baseline focus:ring-indigo-500"
                    />
                    <div className="flex flex-col ml-4">
                      <label
                        htmlFor="is_pin_required"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Require PIN
                      </label>
                      {!is_pin_required ? (
                        <p className={`text-xs first-letter:capitalize text-gray-500`}>
                          Caution: Turning off PIN reduces security. Proceed only if you accept the
                          risks.
                        </p>
                      ) : null}
                      {errors.is_pin_required?.message && (
                        <p className="text-xs text-red-500 first-letter:capitalize">
                          {errors.is_pin_required?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-12">
                  <div className="flex items-center w-full mt-2">
                    <input
                      type="checkbox"
                      disabled={false}
                      id="snapshot"
                      {...snapshotRef}
                      onChange={(e) => {
                        setValue('take_pic', false);
                        setValue('take_pic_on_break', false);
                        setValue('take_pic_on_leave_request', false);
                        snapshotRef.onChange(e);
                      }}
                      className="w-4 h-4 text-indigo-600 border-gray-300 rounded self-baseline focus:ring-indigo-500"
                    />
                    <div className="flex flex-col ml-4">
                      <label htmlFor="snapshot" className="block text-sm font-medium text-gray-700">
                        Activate Snapshot Feature (Recommended)
                      </label>
                      {snapshot ? (
                        <p
                          className={`h-8 pt-1 text-xs first-letter:capitalize ${
                            errors.take_pic?.message ? 'text-gray-500' : 'text-gray-500'
                          }`}
                        >
                          At least one option is required.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                {snapshot ? (
                  <>
                    <div className="pl-6 mb-4 sm:col-span-12">
                      <div className="flex items-center w-full mt-2">
                        <input
                          type="checkbox"
                          id="take_pic"
                          // disabled={take_pic_on_break}
                          {...register('take_pic')}
                          className="w-4 h-4 text-indigo-600 border-gray-300 rounded self-baseline focus:ring-indigo-500"
                        />
                        <div className="flex flex-col ml-4">
                          <label
                            htmlFor="take_pic"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Clock in-Clock out
                          </label>
                          {/* {errors.take_pic?.message && (
                            <p className="pt-1 text-xs text-red-500 first-letter:capitalize">
                              {errors.take_pic?.message}
                            </p>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div className="pl-6 mb-4 sm:col-span-12">
                      <div className="flex items-center w-full">
                        <input
                          type="checkbox"
                          // disabled={!take_pic && !take_pic_on_break}
                          id="take_pic_on_break"
                          {...register('take_pic_on_break')}
                          className="w-4 h-4 text-indigo-600 border-gray-300 rounded self-baseline focus:ring-indigo-500"
                        />
                        <div className="flex flex-col ml-4">
                          <label
                            htmlFor="take_pic_on_break"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Start-End Break
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="pl-6 mb-4 sm:col-span-12">
                      <div className="flex items-center w-full">
                        <input
                          type="checkbox"
                          // disabled={!take_pic && !take_pic_on_leave_request}
                          id="take_pic_on_leave_request"
                          {...register('take_pic_on_leave_request')}
                          className="w-4 h-4 text-indigo-600 border-gray-300 rounded self-baseline focus:ring-indigo-500"
                        />
                        <div className="flex flex-col ml-4">
                          <label
                            htmlFor="take_pic_on_leave_request"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Leave Request
                          </label>
                        </div>
                      </div>
                      <ul className="flex flex-col mt-4 ml-8 space-y-2 text-xs font-medium text-gray-400 list-disc">
                        <li>Images are automatically deleted after 15 days. </li>
                        <li>
                          You have a limited photo storage of 500 MB; once the limit is reached,
                          older photos will be automatically deleted.
                        </li>
                        <li>
                          Photos won&apos;t be saved in their original resolution. A resolution of
                          512x512 is used instead.
                        </li>
                      </ul>
                    </div>
                  </>
                ) : null}
                <div className="mt-4 sm:col-span-12">
                  <label
                    htmlFor="default_staff_login_method"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Employee List Filter
                  </label>
                  <p className="text-sm text-gray-500">
                    Select a filter type to determine which employees can use the Central Terminal
                    app.
                  </p>
                  <div className="mt-2">
                    <select
                      id="staff_filter_types"
                      {...register('staff_filter_types')}
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option disabled value={-1}>
                        Select a staff filter types
                      </option>
                      <option value={'all'}>All</option>
                      <option value={'location'}>Location</option>
                      <option value={'department'}>Department</option>
                    </select>
                  </div>
                  <p className="h-8 pt-1 text-sm text-red-500 first-letter:capitalize">
                    {errors.staff_filter_types?.message}
                  </p>
                </div>
                {staff_filter_types === 'location' && (
                  <div className="sm:col-span-12">
                    <label htmlFor="locations" className="block text-sm font-medium text-gray-700">
                      Location
                    </label>
                    <div className="mt-2">
                      <select
                        id="locations"
                        {...register('locations')}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option disabled value={-1}>
                          Select a location
                        </option>
                        {locationsData.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="h-8 pt-1 text-sm text-red-500 first-letter:capitalize">
                      {errors.locations?.message as string}
                    </p>
                  </div>
                )}
                {staff_filter_types === 'department' && (
                  <div className="sm:col-span-12">
                    <label
                      htmlFor="departments"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Departments
                    </label>
                    <div className="mt-2">
                      <Controller
                        name="departments"
                        control={control}
                        render={({ field }) => (
                          <Select
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            options={departmentsOptions}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <p className="h-8 pt-1 text-sm text-red-500 first-letter:capitalize">
                      {errors.departments?.message}
                    </p>
                  </div>
                )}
                <div className="sm:col-span-12">
                  <label htmlFor="admin-staffs" className="block text-sm font-medium text-gray-700">
                    Admin Selection
                  </label>
                  <p className="text-sm text-gray-500">
                    Choose the admin responsible for managing the Central Terminal app
                  </p>
                  <div className="mt-2">
                    <Controller
                      name="admins.staff"
                      control={control}
                      render={({ field }) => (
                        <Select
                          isMulti={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          options={employeesOptions}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <p className="h-8 pt-1 text-sm text-red-500 first-letter:capitalize">
                    {errors?.admins?.staff?.message}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between w-full">
            <FormError error={[createCentralDevice.error, updateCentralDevice.error]} />
            <div className="flex items-center justify-end">
              {id && (
                <button
                  type="button"
                  disabled={createCentralDevice.isLoading || updateCentralDevice.isLoading}
                  className="flex justify-center py-3 text-sm font-medium text-white bg-red-500 border border-transparent rounded shadow-sm w-36 md:w-40 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => navigate('../devices')}
                >
                  Cancel
                </button>
              )}
              <button
                type="submit"
                className="flex justify-center w-40 py-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded shadow-sm ml-9 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {!createCentralDevice.isLoading && !updateCentralDevice.isLoading ? (
                  'Save'
                ) : (
                  <Spinner size="small" />
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewDevice;
