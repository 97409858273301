import { useCallback, useState } from 'react';
import { useTitle } from 'react-use';
import { EyeIcon } from '@heroicons/react/24/solid';
import NotificationIcon from '~icons/carbon/notification';
import PencilIcon from '~icons/carbon/edit';
import EmailIcon from '~icons/carbon/email';
import TrashIcon from '~icons/carbon/trash-can';
import { Link, useNavigate } from 'react-router-dom';
import { useEmployees } from '../../hooks/use-employees';
import staffsService from '../../services/staffs-service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { Employee } from '../../interfaces/employee-interface';
import Confirmation from '../../components/libs/confirmation';
import EmptyState from '../../components/empty-state';
import Spinner from '../../components/spinner';
import SingleNotificationModal from './components/single-notification-modal';
import MultipleNotificationsModal from './components/multiple-notifications-modal';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';
import { PlusIcon } from '@heroicons/react/24/outline';
import { formatDateToTimeZone } from '../../utils/format-date';
import { useArchivedEmployees } from '../../hooks/use-archived-employees';
import VideoPlayer from '../../components/libs/video-player';
import NotificationsLogModal from './components/notifications-log-modal';
import { customToastError } from '../../utils/custom-toast-error';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { matchSorter } from 'match-sorter';
import { useChangeStaffStatus } from '../../hooks/use-change-staff-status';
import { millisecondsToCustomHours } from '../../utils/min-to-custom-hours';
import { useStaffEmailPinCodeCentralDevice } from '../../hooks/centeral-devices/use-staff-email-pin-code-central-device';
import { Tooltip } from '@mui/material';

type Filter = 'ACTIVE' | 'ARCHIVED';

const EmployeesList = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Employees List`);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<Filter>('ACTIVE');
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [showNotificationsLogModal, setShowNotificationsLogModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showMultipleNotificationModal, setShowMultipleNotificationModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [empId, setEmpId] = useState<Employee['id'] | null>(null);
  const [activeEmployee, setActiveEmployee] = useState<Employee | null>(null);
  const [term, setTerm] = useState('');
  const { data: employees = [], isLoading: empIsLoading } = useEmployees();
  const { data: archivedEmployees = [], isLoading: archivedEmployeesIsLoading } =
    useArchivedEmployees();
  const filteredData = filter === 'ACTIVE' ? employees : archivedEmployees;
  const filteredEmployees = matchSorter(filteredData, term, {
    keys: ['first_name', 'last_name', 'email', 'mobile'],
  });
  const changeStaffStatus = useChangeStaffStatus();
  const staffEmailPinCodeCentralDevice = useStaffEmailPinCodeCentralDevice();

  const { mutate: deleteEmployee, isLoading: deleteIsLoading } = useMutation(
    staffsService.deleteStaff,
    {
      onSuccess: (data, id) => {
        toast.success(`Employee Deleted.`);
        queryClient.setQueryData([staffsService.staffsQueryKey], (old: Employee[] | undefined) => {
          return (old ?? [])?.filter((emp) => {
            if (emp.id === id) {
              return false;
            }
            return true;
          });
        });
        queryClient.setQueryData(
          [staffsService.archivedStaffsQueryKey],
          (old: Employee[] | undefined) => {
            return (old ?? [])?.filter((emp) => {
              if (emp.id === id) {
                return false;
              }
              return true;
            });
          },
        );
      },
      onError: (err: AxiosError<Error>) => {
        customToastError('Error', `${err.response?.data.message}`);
      },
      onSettled: () => {
        queryClient.invalidateQueries([staffsService.staffsQueryKey]);
        queryClient.invalidateQueries([staffsService.archivedStaffsQueryKey]);
      },
    },
  );

  const handleEditEmployee = (id: Employee['id']) => {
    navigate(`./employees-create?employeeId=${id}`);
  };

  const handleConfirmation = (bool: boolean) => {
    if (empId && bool) {
      deleteEmployee(empId);
      setEmpId(null);
    }
    if (activeEmployee && bool) {
      changeStaffStatus.mutate({
        id: activeEmployee.id,
        status: activeEmployee.employment_status === 'archived' ? 'active' : 'archived',
      });
      setActiveEmployee(null);
    }
  };

  const handleDeleteEmployee = (id: Employee['id']) => {
    setEmpId(id);
    handleOpenConfirmation();
  };

  const handleEmail = (id: Employee['id']) => {
    staffEmailPinCodeCentralDevice.mutate(id);
  };

  const handleSendNotificationToEmployee = (id: Employee['id']) => {
    setEmpId(id);
    handleOpenNotificationModal();
  };

  const handleShowDetails = (id: Employee['id']) => {
    navigate(`./employee-details/${id}`);
  };

  const handleOpenConfirmation = useCallback(() => {
    setShowConfirmation(true);
  }, []);

  const handleCloseConfirmation = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  const handleOpenNotificationModal = useCallback(() => {
    setShowNotificationModal(true);
  }, []);

  const handleCloseNotificationModal = useCallback(() => {
    setShowNotificationModal(false);
  }, []);

  const handleOpenNotificationsLogModal = useCallback(() => {
    setShowNotificationsLogModal(true);
  }, []);

  const handleCloseNotificationsLogModal = useCallback(() => {
    setShowNotificationsLogModal(false);
  }, []);

  const handleOpenMultipleNotificationModal = useCallback(() => {
    setShowMultipleNotificationModal(true);
  }, []);

  const handleCloseMultipleNotificationModal = useCallback(() => {
    setShowMultipleNotificationModal(false);
  }, []);

  const handleFilter = (filter: Filter) => {
    setFilter(filter);
  };

  const handleOpenShowVideoPlayer = () => {
    setShowVideoPlayer(true);
  };

  const handleCloseShowVideoPlayer = () => {
    setShowVideoPlayer(false);
  };

  const handleTermChanges = useCallback((term: string) => {
    setTerm(term);
  }, []);

  if (empIsLoading || archivedEmployeesIsLoading) {
    return (
      <div className="flex items-end justify-center w-full my-9">
        <span className="mr-4">Please wait</span>
        <Spinner size="small" />
      </div>
    );
  }

  if (
    empIsLoading === false &&
    !employees?.length &&
    archivedEmployeesIsLoading === false &&
    !archivedEmployees?.length
  ) {
    return (
      <EmptyState
        path="./employees-create"
        buttonName="Add Employee"
        message="No employees yet! Press “Add Employee” to get started"
        showBtn={true}
        showImg={true}
      />
    );
  }

  return (
    <div className="w-full mx-auto space-y-8 pb-14">
      <div className="flex flex-col pt-1 space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-between">
        <div className="flex flex-col space-y-4 md:flex-row md:items-center md:space-y-0 md:space-x-4">
          <Link
            to="./employees-create"
            className="flex items-center justify-center w-full text-sm font-medium text-white transition duration-200 rounded shadow md:w-40 bg-brand-primary hover:bg-brand-primary-dark h-11"
          >
            <PlusIcon className="w-6 h-6 mr-2" />
            Add Employee
          </Link>
          <div className="relative text-gray-400 border border-gray-200 rounded focus-within:text-gray-500">
            <input
              id="mobile-search"
              type="search"
              placeholder="Search ..."
              onChange={(e) => handleTermChanges(e.target.value)}
              className="block w-full pl-10 placeholder-gray-500 border-none rounded-md focus:outline-none focus:ring-brand-primary h-11 "
            />
            <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-3">
              <MagnifyingGlassIcon className="w-5 h-5" aria-hidden="true" />
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4 md:flex-row md:items-center md:space-y-0 md:space-x-4">
          <button
            type="button"
            onClick={handleOpenNotificationsLogModal}
            className="flex items-center justify-center w-full text-sm font-medium transition bg-white border rounded shadow md:w-40 text-brand-primary border-brand-primary hover:bg-brand-primary hover:text-white h-11"
          >
            Notifications Log
          </button>
          <button
            type="button"
            onClick={handleOpenMultipleNotificationModal}
            className="flex items-center justify-center w-full text-sm font-medium transition bg-white border rounded shadow md:w-40 text-brand-primary border-brand-primary hover:bg-brand-primary hover:text-white h-11"
          >
            Send Notifications
          </button>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full p-4 bg-white border border-gray-100 rounded-md shadow-lg md:p-9">
        <div className="flex flex-col w-full pt-2 pb-6 mb-6 border-b md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col">
            <span>Employees List</span>
            <p className="mt-2 text-sm font-normal text-gray-400">
              Here is a quick snapshot of who you have on your team. Check their status, edit or
              delete them if needed.
              <button
                onClick={handleOpenShowVideoPlayer}
                className="ml-2 text-sm font-medium text-left text-blue-600 underline transition w-52 underline-offset-2 hover:text-blue-800"
              >
                Click Here to see a video
              </button>
            </p>
          </div>
          <div className="flex items-center mt-4 md:mt-0">
            <button
              type="button"
              className={`relative w-24 py-2 text-sm font-medium text-gray-700 border-t border-b border-l border-gray-300 rounded-tl rounded-bl ${
                filter === 'ACTIVE' ? 'bg-brand-primary text-white' : 'bg-white'
              }`}
              onClick={() => handleFilter('ACTIVE')}
            >
              Active
            </button>
            <button
              type="button"
              className={`relative w-24 py-2 text-sm font-medium text-gray-700 border-t border-b border-r border-gray-300 rounded-tr rounded-br ${
                filter === 'ARCHIVED' ? 'bg-brand-primary text-white' : 'bg-white'
              }`}
              onClick={() => handleFilter('ARCHIVED')}
            >
              Archived
            </button>
          </div>
        </div>
        <div className="grid w-full grid-cols-12 text-sm font-medium rounded bg-gray-50 text-gray-90 h-14">
          <div className="flex items-center col-span-6 pl-2 pr-2 md:pl-8 md:col-span-3 md:flex">
            <span className="line-clamp-2">Name</span>
          </div>
          <div className="items-center hidden col-span-2 pl-2 pr-2 md:pl-8 md:flex">
            <span className="line-clamp-2">Start Date</span>
          </div>
          <div className="items-center hidden col-span-1 pl-2 pr-2 md:pl-8 md:flex">
            <span className="line-clamp-2">Hour Per Day</span>
          </div>
          <div className="items-center hidden col-span-1 pl-2 pr-2 md:pl-8 md:flex">
            <span className="line-clamp-2">Hour Per Month</span>
          </div>
          <div className="items-center hidden col-span-2 pl-2 pr-2 md:pl-8 md:flex">
            <span className="line-clamp-2">Role</span>
          </div>
          <div className="flex items-center col-span-6 pl-2 pr-2 md:pl-8 md:col-span-3 md:flex">
            <span className="line-clamp-2">Actions</span>
          </div>
        </div>
        <div className="flex flex-col w-full text-gray-700 divide-y divide-gray-100">
          {filteredEmployees.map((employee) => {
            return (
              <div className="grid w-full h-16 grid-cols-12 text-sm" key={employee.email}>
                <div className="flex items-center w-full col-span-6 pl-2 pr-2 md:pl-8 md:flex md:col-span-3">
                  <div
                    className={`hidden md:flex flex-shrink-0 w-10 h-10 rounded-full overflow-hidden ${
                      employee?.tokens?.length ? 'ring-offset-2 ring-[3px] ring-[#592d88]' : ''
                    }`}
                  >
                    {employee.attachment ? (
                      <img
                        className="object-cover w-10 h-10 rounded-full shrink-0 grow-0"
                        src={employee.attachment}
                        alt={employee.first_name}
                      />
                    ) : (
                      <svg
                        className="w-10 h-10 text-[#592d88]"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    )}
                  </div>
                  <div className="overflow-hidden md:ml-3">
                    <span
                      title={capitalizeFirstLetter(`${employee.first_name} ${employee.last_name}`)}
                      className="font-medium text-left line-clamp-1"
                    >
                      {capitalizeFirstLetter(`${employee.first_name} ${employee.last_name}`)}
                    </span>
                    <span
                      title={employee.email}
                      className="text-left text-gray-500 break-all line-clamp-1"
                    >
                      {employee.email}
                    </span>
                  </div>
                </div>
                <div className="flex-col justify-center hidden w-full col-span-2 pl-2 pr-2 md:pl-8 md:flex">
                  <span className="font-medium first-letter:capitalize">
                    {formatDateToTimeZone(+employee.official_start_date, 'dd-MM-y')}
                  </span>
                </div>
                <div className="flex-col justify-center hidden w-full col-span-1 pl-2 pr-2 md:pl-8 md:flex">
                  {employee.day_hour <= 0 ? (
                    <span
                      onClick={() => handleEditEmployee(employee.id)}
                      className={`flex items-center justify-center px-2 py-1 text-xs font-semibold leading-5 rounded-full text-red-800 bg-red-100 cursor-pointer`}
                    >
                      Not Set
                    </span>
                  ) : (
                    <div className="">{millisecondsToCustomHours(employee.day_hour)}</div>
                  )}
                </div>
                <div className="flex-col justify-center hidden w-full col-span-1 pl-2 pr-2 md:pl-8 md:flex">
                  {employee.hour_per_month <= 0 ? (
                    <span
                      onClick={() => handleEditEmployee(employee.id)}
                      className={`flex items-center justify-center px-2 py-1 text-xs font-semibold leading-5 rounded-full text-red-800 bg-red-100 cursor-pointer`}
                    >
                      Not Set
                    </span>
                  ) : (
                    <div className="">{millisecondsToCustomHours(employee.hour_per_month)}</div>
                  )}
                </div>
                <div className="flex-col justify-center hidden w-full col-span-2 pl-2 pr-2 md:pl-8 md:flex">
                  {capitalizeFirstLetter(employee?.job?.title)}
                </div>
                <div className="flex items-center col-span-6 pl-2 pr-2 space-x-2 md:pl-8 md:flex md:col-span-3 2xl:space-x-4">
                  {filter === 'ACTIVE' ? (
                    <Tooltip title="Delete">
                      <button
                        type="button"
                        className=""
                        disabled={deleteIsLoading}
                        onClick={() => handleDeleteEmployee(employee.id)}
                      >
                        <TrashIcon className="w-6 h-6 text-gray-400 transition hover:text-gray-800" />
                      </button>
                    </Tooltip>
                  ) : null}
                  <Tooltip title="Edit">
                    <button
                      type="button"
                      className=""
                      disabled={deleteIsLoading || empIsLoading}
                      onClick={() => handleEditEmployee(employee.id)}
                    >
                      <PencilIcon className="w-6 h-6 text-gray-400 transition hover:text-gray-800" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Notification">
                    <button
                      type="button"
                      className=""
                      disabled={deleteIsLoading}
                      onClick={() => handleSendNotificationToEmployee(employee.id)}
                    >
                      <NotificationIcon className="w-6 h-6 text-gray-400 transition hover:text-gray-800" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Send login details">
                    <button
                      type="button"
                      className=""
                      disabled={staffEmailPinCodeCentralDevice.isLoading || deleteIsLoading}
                      onClick={() => handleEmail(employee.id)}
                    >
                      <EmailIcon className="w-6 h-6 text-gray-400 transition hover:text-gray-800" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Details">
                    <button
                      type="button"
                      className=""
                      disabled={deleteIsLoading}
                      onClick={() => handleShowDetails(employee.id)}
                    >
                      <EyeIcon className="w-6 h-6 text-gray-400 transition hover:text-gray-800" />
                    </button>
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
      />
      <NotificationsLogModal
        show={showNotificationsLogModal}
        closeModal={handleCloseNotificationsLogModal}
      />
      <SingleNotificationModal
        show={showNotificationModal}
        closeModal={handleCloseNotificationModal}
        empId={empId}
      />
      <MultipleNotificationsModal
        show={showMultipleNotificationModal}
        closeModal={handleCloseMultipleNotificationModal}
      />
      <VideoPlayer
        closeModal={handleCloseShowVideoPlayer}
        show={showVideoPlayer}
        title={'Add Employee'}
        url={import.meta.env.VITE_PREFIX_ADD_EMPLOYEE_VIDEO}
      />
    </div>
  );
};

export default EmployeesList;
